import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, DefaultButton, IconButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { buttonAddStyle, buttonRemoveStyle, stackDynamicItems, stackDynamicSingleItem, itemStyle } from './react_styles_ui';

interface Item {
    textValue: string;
};

interface Props {
    onSelectionChange: (textValue: string, index: number) => void;
    itemSelection: { textValue: string }[];
    removeItem: (index: number) => void;
};


const DynamicTextfield: React.FC<Props> = ({ onSelectionChange, itemSelection, removeItem }) => {

    // Initialize items
    const [items, setItems] = useState<Item[]>(itemSelection);

    // Handle add items
    const handleAddItems = () => {
        // Check if previous item has a non-empty text value before adding a new item
        const lastItem = items[items.length - 1];
        if (lastItem && lastItem.textValue.trim() === '') {
            toast.info(`Please fill in the text field before adding a new item!`);
            return;
        }
        setItems([...items, { textValue: '' }]);
    };

    // Update selected items
    useEffect(() => {
        setItems(itemSelection);
    }, [itemSelection]);

    // Construct DynamicTextfield component
    return (
        <div>
            <DefaultButton
                onClick={handleAddItems}
                styles={buttonAddStyle}
                iconProps={{ iconName: 'Add' }}
                data-testid="add-button"
            />
            {items.map((item, index) => (
                <Stack
                    key={index}
                    horizontal
                    tokens={stackDynamicItems}
                    styles={stackDynamicSingleItem}
                >
                    <TextField
                        value={item.textValue}
                        onChange={(_, value) => {
                            const newItems = [...items];
                            newItems[index].textValue = value || '';
                            onSelectionChange(value || '', index);
                        }}
                        placeholder="Enter text"
                        styles={itemStyle}
                    />
                    <IconButton
                        onClick={() => removeItem(index)}
                        styles={buttonRemoveStyle}
                        iconProps={{ iconName: 'Delete' }}
                        data-testid="delete-button"
                    />
                </Stack>
            ))}
        </div>
    );
};

export default DynamicTextfield;