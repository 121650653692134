import React from 'react';
import { Outlet, Link } from "react-router-dom";
import dfine from "../../assets/D-fine_logo.svg";
import styles from "./Layout.module.css";
import Logout from '../../components/ui/logout_button';
import { useAuth } from '../../components/auth/auth_context';


const Layout = () => {

    // Initialize constant
    const { logout  } = useAuth();

    // Construct Layout component
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Skill Search App</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNav}>
                                <a href="https://d-fine.de" target={"_blank"} rel="noreferrer" title="d-fine link">
                                    <img
                                        src={dfine}
                                        alt="d-fine logo"
                                        aria-label="Link to d-fine"
                                        width="100px"
                                        height="100px"
                                        className={styles.githubLogo}
                                    />
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <Logout onLogout={logout} /> 
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;