import { Tag } from "@fluentui/react-components";
import { MessageBar, Label, mergeStyles } from '@fluentui/react';
import { tagStyle, labelStyleDetails, messageBarIconStyle, messageBarDivStyle, messageBarTagsStyle, tagsDivStyle } from './react_styles_ui';

interface CustomTagProps {
    stringToParse: string;
    separator: string;
    label: string;
};

const DynamicTags = ({ stringToParse, separator, label }: CustomTagProps) => {
    // Split the string based on the separator
    const items = stringToParse.split(separator);

    return (
        <div style={messageBarDivStyle} data-testid="DynamicTags">      
            <MessageBar 
                isMultiline={true} 
                messageBarIconProps={{ iconName: 'CompletedSolid', styles: messageBarIconStyle}}
                styles={messageBarTagsStyle}
            >
                <Label styles={labelStyleDetails} >{label}</Label>
                    <div className={mergeStyles(tagsDivStyle)}>
                        {items.map((item, index) => (
                            <Tag 
                                key={index}  
                                style={tagStyle} 
                            >
                                {item.trim()}
                            </Tag>
                        ))}
                    </div>
            </MessageBar>
        </div>
    );
};

export default DynamicTags;