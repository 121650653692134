import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Label, Stack, IDropdownOption } from '@fluentui/react';
import { useCookies } from 'react-cookie';
import { AgGridReact } from 'ag-grid-react';
import { ITextFilterParams } from "ag-grid-community";
import { useMemo } from "react";
import CVLink from './cv_link';
import ChatAssistantLink from './chat_assistant_link';
import MultiselectDropdown from './multiselect_dropdown';
import {labelStyle, 
        labelStyleDetails,
        buttonChatExpanderStyle, 
        stackExpanderField, 
        dataStatisticsStyle, 
        cvDataDiv, 
        cvDataAgGridDiv, 
        buttonModalCloseStyle, 
        dataTableModalStyle, 
        modalContentDiv 
} from './react_styles_ui';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Modal from 'react-modal';
import {getMaxTextWidth, 
        updateOptions, 
        percentageCellRenderer, 
        daysCellRenderer,
        daysValueGetter,
        kmCellRenderer
} from './functions';

//Modal.setAppElement("#root"); // Currently not needed, app works fine without it. If integrated, it leads to errors when testing DataTable component. Mocking also did not work to solve the error.

interface RowData {
    [key: string]: any;
};
  
interface Props {
    data: RowData[];
};


const DataTable: React.FC<Props> = ({ data }) => {

  // Default column selection
  const columnsOption: IDropdownOption[] = useMemo(() => { 
    return [
    { key: 'Absences_html', text: 'Absences' },
    { key: 'Availability', text: 'Availability' },
    { key: 'CareerLevel', text: 'Career Level' },
    { key: 'CareerTrack', text: 'Career Track' },
    { key: 'Diamonds_html', text: 'Diamonds' },
    { key: 'EntryDate', text: 'Entry Date' },
    { key: 'Expertise_html', text: 'Expertise' },
    { key: 'FirstMonthAbsencesDays', text: 'First Month Absences (days)' },
    { key: 'FirstMonthAbsencesPercent', text: 'First Month Absences (%)' },
    { key: 'Highlights', text: 'Highlights' },
    { key: 'HighlightFields_html', text: 'Highlight Fields' },
    { key: 'CorpId', text: 'ID' },
    { key: 'Industry_html', text: 'Industry' },
    { key: 'LastModified', text: 'Last Modified' },
    { key: 'Name', text: 'Name' },
    { key: 'NewProjects_html', text: 'New Projects' },
    { key: 'ProjectAbsencesDays', text: 'Project Absences (days)' },
    { key: 'ProjectAbsencesPercent', text: 'Project Absences (%)' },
    { key: 'ProjectLocationDistance', text: 'Project Location Distance' },
    { key: 'Residence', text: 'Residence' },
    { key: '@search.score', text: 'Search Score' },
    { key: '@search.reranker_score', text: 'Semantic Score' },
    { key: 'Skills_html', text: 'Skills' },
    { key: 'StaffingStatus', text: 'Status' },
    { key: 'TenderCVs_html', text: 'Tender CVs' },
    { key: 'OrgUnit', text: 'Unit' },
    { key: 'RelevantWorkExperiences_DE_html', text: 'Work Experience (DE)' },
    { key: 'RelevantWorkExperiences_EN_html', text: 'Work Experience (EN)' },
  ]
  }, []);

  // Initialize states
  const [dataVisible, setDataVisible] = useState(false);
  const [gridApi, setGridApi] = useState<any | null>(null);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  const [columnOptionsUpdate, setColumnOptionsUpdate] = useState<IDropdownOption[]>(columnsOption);
  const [selectedColumns, setSelectedColumns] = useState<IDropdownOption[]>(columnOptionsUpdate);
  const [cookies, setCookie] = useCookies(['selectedColumns', 'columnOptions']);
  const modalContentDivRef = useRef<HTMLDivElement | null>(null);

  // Determine statistics
  const statistics = useMemo(() => {
      const numberOfResults = data.length;
      return { numberOfResults };
  }, [data]);

  // Grid settings
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };
  const onSelectionChanged = () => {
    const selectedRows = gridApi?.getSelectedRows();
    setSelectedItem(selectedRows?.length === 1 ? selectedRows[0] : null);
  };
  const openModal = (content: string) => {
    setModalContent(content);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleClick = (value: any) => {
    openModal(value);
  };
  const columnDefs = selectedColumns.map((option) => {
    const key =  option.key.toString()
    const text =  option.text.toString()
    if (key === 'Absences_html' || key === 'Industry_html' || key === 'TenderCVs_html' || key === 'NewProjects_html' || key === 'Diamonds_html' || key === 'HighlightFields_html' || key === 'RelevantWorkExperiences_DE_html' || key === 'RelevantWorkExperiences_EN_html' || key === 'Skills_html' || key === 'Expertise_html') {
      // Define cellRenderer for specific columns
      return {
        headerName: text,
        field: key,
        cellRenderer: (params: any) => (
          <div onClick={() => handleClick(params.value)} dangerouslySetInnerHTML={{ __html: params.value }} />
        ),
        filter: "agTextColumnFilter",
        floatingFilter: true,
        filterParams: {
          "browserDatePicker": false,
          buttons: ["reset", "apply"],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (key === '@search.score' || key === '@search.reranker_score') {
      // Define valueGetter for scores
      return {
        headerName: text,
        field: key,
        cellRenderer: undefined,
        valueGetter: (params: any) => params.data[key],
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        filterParams: {
          "browserDatePicker": false,
          buttons: ["reset", "apply"],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (key === 'ProjectAbsencesDays' || key === 'FirstMonthAbsencesDays') {
      // Define valueGetter and cellRenderer for absences
      return {
        headerName: text,
        field: key,
        cellRenderer: daysCellRenderer,
        valueGetter: daysValueGetter,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        filterParams: {
          "browserDatePicker": false,
          buttons: ["reset", "apply"],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    }  else if (key === 'ProjectAbsencesPercent' || key === 'FirstMonthAbsencesPercent') {
      // Define cellRenderer for % columns
      return {
        headerName: text,
        field: key,
        cellRenderer: percentageCellRenderer,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        filterParams: {
          "browserDatePicker": false,
          buttons: ["reset", "apply"],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    } else if (key === 'ProjectLocationDistance') {
      // Define cellRenderer for km columns
      return {
        headerName: text,
        field: key,
        cellRenderer: kmCellRenderer,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        filterParams: {
          "browserDatePicker": false,
          buttons: ["reset", "apply"],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    }  else {
      // For other columns nothing special is needed
      return {
        headerName: text,
        field: key,
        filter: true,
        floatingFilter: true,
        filterParams: {
          "browserDatePicker": false,
          buttons: ["reset", "apply"],
          closeOnApply: true,
        } as ITextFilterParams,
      };
    }
  });

  // Function to update column options and selections when moving columns
  const onColumnMoved = (event: any) => {
    const columnState: any[] = event.api.getColumnState();
    const newColumnOrder: IDropdownOption[] = columnState.map((column: any) => ({
        key: column.colId,
        text: columnsOption.find((option) => option.key === column.colId)?.text || column.headerName,
    }));
    const updatedOptions = updateOptions(selectedColumns, columnsOption);
    setSelectedColumns(newColumnOrder);
    setColumnOptionsUpdate(updatedOptions);
    setCookie('selectedColumns', newColumnOrder, { secure: true, maxAge: 31536000 });
    setCookie('columnOptions', updatedOptions, { secure: true, maxAge: 31536000 });
  };

  // Function to update column options and selections based on dropdown selection order
  const handleColumnChange = (updatedSelectedItems: IDropdownOption[]) => {
    const updatedOptions = updateOptions(selectedColumns, columnsOption);
    setSelectedColumns(updatedSelectedItems);
    setColumnOptionsUpdate(updatedOptions);
    setCookie('selectedColumns', updatedSelectedItems, { secure: true, maxAge: 31536000 }); 
    setCookie('columnOptions', updatedOptions, { secure: true, maxAge: 31536000 }); 
  };

  // Function to open a new tab via double click
  const handleDoubleClick = (event: any) => {
    const selectedRowData = event.data;
    const link = `https://app.it-docker.d-fine.local/StaffingPortal/StaffApp/details/CV_View/${selectedRowData.CorpId}`;
    if (link) {
      window.open(link, '_blank');
    }
  };

  // Function to copy the file path in Tender CVs on button click
  const handleButtonClick = (event: Event) => {
    const target = event.currentTarget as HTMLButtonElement;
    const filePath = target.getAttribute('data-filepath');
    console.log('Button clicked, file path:', filePath);
    if (filePath) {
      navigator.clipboard.writeText(filePath).catch(err => {
        console.error('Failed to copy path: ', err);
      });
    }
  };

  // Calculate column width on first load
  useEffect(() => {
    if (!gridApi || data.length === 0 || !firstLoad) return;
    const columns = gridApi?.getColumnDefs();
    if (!columns) return;
    columns.forEach((column: any) => {
      const font = 'bold 20px roboto';
      const maxWidth = getMaxTextWidth(data, column.field, font) + 100;
      const preferredWidth = Math.min(Math.ceil(maxWidth), 350); 
      gridApi?.setColumnWidths([{key: column.field, newWidth: preferredWidth}]);
    });
    setFirstLoad(true);
  }, [gridApi, columnDefs, data, firstLoad]);

  // Event listener to prevent scrolling of the main window when mouse is over the modal content div
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (modalIsOpen && modalContentDivRef.current && modalContentDivRef.current.contains(e.target as Node)) {
        const element = modalContentDivRef.current;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const height = element.clientHeight;
        const wheelDelta = e.deltaY;
        const isDeltaPositive = wheelDelta > 0;
        if (
          (isDeltaPositive && scrollTop + height >= scrollHeight) || 
          (!isDeltaPositive && scrollTop <= 0) 
        ) {
          e.preventDefault();
        }
      }
    };
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
        window.removeEventListener('wheel', handleWheel);
      };
  }, [modalIsOpen]);

  // Event listener to copy file path for Tender CVs
  const addEventListeners = () => {
    const modalDiv = modalContentDivRef.current;
    if (modalDiv) {
      const buttons = modalDiv.querySelectorAll('.styled-button');
      buttons.forEach(button => {
        button.addEventListener('click', handleButtonClick);
      });
    }
  };
  const removeEventListeners = () => {
    const modalDiv = modalContentDivRef.current;
    if (modalDiv) {
      const buttons = modalDiv.querySelectorAll('.styled-button');
      buttons.forEach(button => {
        button.removeEventListener('click', handleButtonClick);
      });
    }
  };
  useEffect(() => {
    if (modalIsOpen) {
      const timeoutId = setTimeout(addEventListeners, 0);
      return () => {
        clearTimeout(timeoutId);
        removeEventListeners();
      };
    }
  }, [modalIsOpen]);

  // Load column selection and order from cookies on component mount
  useEffect(() => {
    const storedColumnSelection = cookies['selectedColumns'];
    const storedColumnOptions = cookies['columnOptions'];
    if (storedColumnSelection && storedColumnOptions) {
      setSelectedColumns(storedColumnSelection);
      setColumnOptionsUpdate(storedColumnOptions);
    } else {
      setSelectedColumns(columnsOption);
    }
  }, [columnsOption, cookies]); 

  // Event listener for moving columns
  useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener('columnMoved', onColumnMoved);
    }
    return () => {
      if (gridApi) {
        gridApi.removeEventListener('columnMoved', onColumnMoved);
      }
    };
  }, [gridApi, onColumnMoved]);

  // Construct DataTable component
  return (
    <div data-testid="DataTable">
      <Label styles={labelStyle}>Skill data:</Label>
      <div>
        <div className={dataStatisticsStyle}>
          <div>Total number of retrieved candidates: {statistics.numberOfResults}</div>
        </div>
      </div>
      <Stack
        horizontal
        styles={stackExpanderField} 
      >
        <IconButton
          iconProps={{ iconName: dataVisible ? 'ChevronUp' : 'ChevronDown' }}
          title={dataVisible ? 'Hide Data' : 'Show Data'}
          ariaLabel={dataVisible ? 'Hide Data' : 'Show Data'}
          onClick={() => setDataVisible(prevVisible => !prevVisible)}
          styles={buttonChatExpanderStyle}
        />
        <Label styles={labelStyleDetails}>{dataVisible ? 'Hide data' : 'Show data'}</Label>
      </Stack>
      {dataVisible && (
        <>
          <MultiselectDropdown
            placeholder="Select columns..."
            options={columnsOption}
            optionsStored={columnOptionsUpdate}
            selectedOptions={selectedColumns}
            onChange={handleColumnChange}
            adjustOrder={true}
          />
          <div className='ag-theme-alpine'>
            <div className={cvDataDiv}>
              <div className={cvDataAgGridDiv} data-testid="grid">
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  rowData={data}
                  rowSelection='single'
                  onSelectionChanged={onSelectionChanged}
                  onRowDoubleClicked={(event: any) => handleDoubleClick(event)}
                  onColumnMoved={onColumnMoved}
                  onColumnPinned={onColumnMoved}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={dataTableModalStyle}
              ariaHideApp={false}
            >   
              <IconButton 
                onClick={closeModal} 
                styles={buttonModalCloseStyle}
                iconProps={{ iconName: 'Clear' }}
                data-testid="close-modal"
              />
              <div 
                className={modalContentDiv} 
                dangerouslySetInnerHTML={{ __html: modalContent }}
                ref={modalContentDivRef}
                data-testid="modal-content"
              />  
            </Modal>
          </div>
          {selectedItem && (
            <Stack
              horizontal
            >
              <CVLink CorpID={selectedItem.CorpId} />
              <ChatAssistantLink rowData={selectedItem} />
            </Stack>
          )}
        </>
      )}
    </div>
  );
};

export default DataTable;