import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slider, TextField, DefaultButton, IconButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { buttonAddStyle, buttonRemoveStyle, stackDynamicItems, stackDynamicSingleItem, itemStyle, getSliderStyles } from './react_styles_ui';

interface Item {
    textValue: string;
    sliderValue: number;
};

interface Props {
    maximumSliderLevel: number;
    sliderLevelMapping: string[];
    onSelectionChange: (textValue: string, sliderValue: number, index: number) => void;
    itemSelection: { textValue: string; sliderValue: number }[];
    removeItem: (index: number) => void;
};


const DynamicTextfieldSlider: React.FC<Props> = ({ onSelectionChange, maximumSliderLevel, sliderLevelMapping, itemSelection, removeItem }) => {

    // Initialize items
    const [items, setItems] = useState<Item[]>(itemSelection);

    // Handle add items
    const handleAddItems = () => {
        // Check if previous item has a non-empty text value before adding a new item
        const lastItem = items[items.length - 1];
        if (lastItem && lastItem.textValue.trim() === '') {
            toast.info(`Please fill in the text field before adding a new item!`);
            return;
        }
        setItems([...items, { textValue: '', sliderValue: 0 }]);
    };

    // Update selected items
    useEffect(() => {
        setItems(itemSelection);
    }, [itemSelection]);

    // Construct DynamicTextfieldSlider component
    return (
        <div>
            <DefaultButton
                onClick={handleAddItems}
                styles={buttonAddStyle}
                iconProps={{ iconName: 'Add' }}
                wrap="true"
                data-testid="add-button"
            />
            {items.map((item, index) => (
                <Stack
                    key={index}
                    horizontal
                    tokens={stackDynamicItems}
                    styles={stackDynamicSingleItem}
                >
                    <TextField
                        value={item.textValue}
                        onChange={(_, value) => {
                            const newItems = [...items];
                            newItems[index].textValue = value || '';
                            onSelectionChange(value || '', newItems[index].sliderValue, index);
                        }}
                        placeholder="Enter text"
                        styles={itemStyle}
                    />
                    <Slider
                        min={0}
                        max={maximumSliderLevel}
                        value={item.sliderValue}
                        valueFormat={(value) => sliderLevelMapping[value]}
                        onChange={(value) => {
                            const newItems = [...items];
                            newItems[index].sliderValue = value;
                            setItems(newItems);
                            onSelectionChange(item.textValue, value, index);
                        }}
                        showValue
                        disabled={!item.textValue.trim()}
                        styles={getSliderStyles(item.sliderValue)}
                    />
                    <IconButton
                        onClick={() => removeItem(index)}
                        styles={buttonRemoveStyle}
                        iconProps={{ iconName: 'Delete' }}
                        data-testid="delete-button"
                    />
                </Stack>
            ))}
        </div>
    );
};

export default DynamicTextfieldSlider;