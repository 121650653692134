import { useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import './index.css';
import Layout from './pages/layout/Layout';
import NoPage from './pages/NoPage';
import { ToastContainer } from 'react-toastify';
import './toast.css';
import SearchComponent from './components/SearchComponent';
import Results from './components/Results';
import StatisticsComponent from './components/StatisticsComponent';
import { customBoxStyleSettings, customBoxStyleResults, } from './react_styles_main'
import { useAuth, AuthProvider } from './components/auth/auth_context';
import Login from './pages/login/Login';

initializeIcons();

interface ChatMessage {
  content: string;
  role: string;
}


// Application component
const App: React.FC = () => {
  return (
    <AuthProvider>
      <Main />
    </AuthProvider>
  );
};

const Main: React.FC = () => {

  /* Constant for search result */
  const [results, setResults] = useState<{ data: any[], searchQuery: string, ragResult: ChatMessage, ragMessages: any[] } | null>(null);
  const [isRag, setIsRag] = useState<boolean>(false);
  const { user, login, advancedRoleName, simpleRoleName } = useAuth();

  return (
    <div>
      {!user ? (
        <Login onLogin={login} />
      ) : (
        <HashRouter>
          <Routes>
            <Route path="/" element={
              <div>
                {user.role === advancedRoleName || user.role === simpleRoleName ? (
                  <>
                    <Layout />
                    <ToastContainer position="top-center" />
                    <div className={customBoxStyleSettings}>
                      {/* <StatisticsComponent/> */}
                      <SearchComponent setResults={setResults} setIsRag={setIsRag} />
                    </div>
                    {results &&
                      <div className={customBoxStyleResults}>
                        <Results data={results.data} searchQuery={results.searchQuery} ragResult={results.ragResult} ragMessages={results.ragMessages} boolRag={isRag} />
                      </div>
                    }
                  </>
                ) : (
                  <Login onLogin={login} />
                )}
              </div>
            } />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </HashRouter>
      )}
    </div>
  );
};

export default App;