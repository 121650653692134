import { IStackStyles, ISpinButtonStyles, IToggleStyles, ISpinnerStyles } from '@fluentui/react';


export const statsHeaderDiv = {
    display: 'block', 
    marginRight: '5px', 
    width: '220px'
};


export const searchHeaderDiv = {
    display: 'block', 
    marginRight: '5px', 
    width: '240px',
    color: '#003c50',
};


export const searchLoadSettingsDiv = {
    display: 'block', 
    marginTop: '50px',
    marginBottom: '50px', 
    marginRight: '20px', 
    width: '100%',
};


export const searchSaveSettingsDiv = {
    display: 'block', 
    marginTop: '50px',
    marginBottom: '30px', 
    marginRight: '20px', 
    width: '100%',
    overflowY: 'auto',
};


export const searchSettingsDiv = {
    display: 'block', 
    marginTop: '30px', 
    marginRight: '30px', 
    width: '100%',
    minHeight: '100px',
    marginLeft: '30px',
    fontSize: '0.9rem', 
    fontFamily: 'Roboto',
};


export const searchSettingsPromptDiv = {
    display: 'block', 
    marginTop: '30px', 
    marginRight: '30px', 
    width: '33%',
    marginLeft: '30px',
};


export const stackSearchSettings: Partial<IStackStyles> = { 
    root: { 
        marginBottom: 20,
        overflowY: 'auto',
        minWidth: '1100px',
        paddingLeft: '60px',
        paddingRight: '60px',
    } 
};


export const statisticsDiv = {
    display: 'block', 
    marginTop: '30px', 
    marginRight: '20px', 
    width: '33%'
};


export const stackStatistics: Partial<IStackStyles> = { 
    root: { 
        marginBottom: 40 
    } 
};


export const labelStyle = {
    root: { 
      fontWeight: 'bold', 
      fontSize: '1.0rem', 
      marginBottom: '8px',
      marginRight: '5px',
      fontFamily: 'Roboto',
     }
};


export const labelSettingDetailsStyle = {
    root: { 
      fontWeight: 'bold', 
      fontSize: '1.25rem', 
      fontFamily: 'Roboto',
      color: '#003c50',
     }
};


export const buttonStyleExpander = {
    root: {
        width: 110,
        fontSize: '11px',
        fontWeight: '500',
        backgroundColor: '#003c50',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        letterSpacing: '0.02857em',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        outline: 'none',
    },
    rootHovered: {
        backgroundColor: '#003c50d4',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootPressed: {
        backgroundColor: '#003c50',
        outline: 'none',
        borderColor: 'transparent'
    },
};


export const textFieldNewSearchSetting = { 
    root: { 
        width: '300px',
        fontFamily: 'Roboto',
        borderRadius: '0.5rem',
        marginLeft: 'auto',
    },
    fieldGroup: {
        color: '#003c50',
        borderRadius: '0.5rem',
        border: '1px solid #ccc', 
        padding: '2px',
        selectors: {
          ':hover': {
            borderColor: '#003c50', 
          },
          ':focus': {
            borderColor: '#003c50', 
            borderRadius: '0.5rem',
          },
          ':focus-within': {
            borderColor: '#003c50',
            borderRadius: '0.5rem',
          },
          '::after': {
            borderRadius: '0.5rem !important',
            border: '2px solid #003c50 !important', 
          },
          ':hover::after': {
            borderColor: '#003c50 !important',
          },
          ':focus-within::after': {
            borderColor: '#003c50 !important',
          },
          ':focus::after': {
            borderColor: '#003c50 !important',
          },
        },
    },
    field: { 
        fontFamily: 'Roboto',
        color: '#003c50',
        resize: 'vertical' 
    }, 
    subComponentStyles: {
        label: {
          root: {
            selectors: {
              ':focus-within': {
                color: '#639aaf',
                borderRadius: '0.5rem',
              },
            },
          },
        },
    },
}; 


export const toggleStyles: Partial<IToggleStyles> = { 
    root: { 
        fontFamily: 'Roboto',
        selectors: {
            '&.is-checked .ms-Toggle-background': {
              color: '#ccc',
              borderColor: '#003c50 !important',
              backgroundColor: '#003c50', 
            },
        },
    },     
    pill: {
        borderColor: '#ccc',
        ':hover': {
            borderColor: '#ccc !important',
        },
        ':hover .ms-Toggle-thumb': {
            borderColor: '#003c50 !important',
        },
    },
}; 


export const buttonStyleLoadDelSavedSetting = {
    root: {
        width: '10%',
        fontSize: '11px',
        fontWeight: '500',
        backgroundColor: '#F07D00',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        letterSpacing: '0.02857em',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '20px',
    },
    rootHovered: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootPressed: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootFocused: {
        backgroundColor: '#F07D00',
        outline: 'none',
        borderColor: 'black' 
    },
    rootDisabled: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
};


export const buttonStyleResetSetting = {
    root: {
        width: '10%',
        fontSize: '11px',
        fontWeight: '500',
        backgroundColor: '#F07D00',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        letterSpacing: '0.02857em',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: 'auto',
    },
    rootHovered: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootPressed: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootFocused: {
        backgroundColor: '#F07D00',
        outline: 'none',
        borderColor: 'black' 
    },
};


export const buttonStyleSaveSetting = {
    root: {
        width: '10%',
        fontSize: '11px',
        fontWeight: '500',
        backgroundColor: '#F07D00',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        letterSpacing: '0.02857em',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '20px',
    },
    rootHovered: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootPressed: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootFocused: {
        backgroundColor: '#F07D00',
        outline: 'none',
        borderColor: 'black' 
    },
    rootDisabled: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
};


export const buttonStyleSearch = {
    root: {
        width: '100%',
        fontSize: '11px',
        fontWeight: '500',
        backgroundColor: '#F07D00',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        letterSpacing: '0.02857em',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '20px',
    },
    rootHovered: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootPressed: {
        backgroundColor: '#D06900',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootFocused: {
        backgroundColor: '#F07D00',
        outline: 'none',
    },
};


export const textFieldStyleSearch = { 
    fieldGroup: {
        color: '#003c50',
        borderRadius: '0.5rem',
        border: '1px solid #ccc', 
        padding: '2px',
        selectors: {
          ':hover': {
            borderColor: '#003c50', 
          },
          ':focus': {
            borderColor: '#003c50', 
            borderRadius: '0.5rem',
          },
          ':focus-within': {
            borderColor: '#003c50',
            borderRadius: '0.5rem',
          },
          '::after': {
            borderRadius: '0.5rem !important',
            border: '2px solid #003c50 !important', 
          },
          ':hover::after': {
            borderColor: '#003c50 !important',
          },
          ':focus-within::after': {
            borderColor: '#003c50 !important',
          },
          ':focus::after': {
            borderColor: '#003c50 !important',
          },
        },
    },
    root: { 
        width: '100%',
    }, 
    field: { 
        fontFamily: 'Roboto',
        color: '#003c50',
        resize: 'vertical' 
    }, 
    subComponentStyles: {
        label: {
          root: {
            selectors: {
              ':focus-within': {
                color: '#639aaf',
                borderRadius: '0.5rem',
              },
            },
          },
        },
    },
}; 


export const expanderDivStyle = {
    root: {
        padding: '20px',
        marginBottom: '30px',
        //backgroundColor: '#dce5e754',
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: '0 2px 2px rgba(0,0,0,.3)',
        borderRadius: '.5rem',
        overflowY: 'auto',
    },   
};


export const expanderSettingDetailsStyle = {
    root: {
        backgroundColor: '#dce5e730',
        border: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: '.5rem',
        overflowY: 'auto',
        marginLeft: '50px',
        marginRight: '50px',
        marginBottom: '20px',
        marginTop: '20px',
    },   
};


export const stackExpanderField = { 
    root: { 
        margingBottom: '50px',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '4px', 
    } 
};


export const buttonSettingsDetailsStyle = {
    root: {
        fontSize: '11px',
        fontWeight: '500',
        backgroundColor: '#003e52',
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        letterSpacing: '0.02857em',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        outline: 'none',
        marginRight: 10,
        color: 'white'
      },
    rootHovered: {
      backgroundColor: '#003e52db', 
      outline: 'none',
      color: 'white'
    },
    rootPressed: {
      backgroundColor: '#003e52db', 
      outline: 'none',
      color: 'white'
    },
    rootFocused: {
      backgroundColor: '#003e52', 
      outline: 'none',
      color: 'white' 
    },
};


export const textFieldItem = {
    root: {
        width: '90%',
    },
};


export const buttonItem = {
    root: {
        width: '10%',
    },
};


export const searchTextFieldStackStyle = {
    root: {
        paddingRight: '20px',
        marginBottom: '10px',
        borderRadius: '.25rem',
    },   
};


export const stackSearchField = { 
    root: { 
        width: '100%',
        paddingRight: '20px',
        overflowY: 'auto',
        minWidth: '100px'
    } 
};


export const searchResultDivStyle = {
    height: 'auto', 
    width: '100%',
};


export const spinButtonStyle: Partial<ISpinButtonStyles> = {
    root: {
        maxWidth: 300,
        marginBottom: 15,
        borderRadius: '0.5rem !important',
    },
    spinButtonWrapper: {
        selectors: {
            '::after': {
              borderRadius: '0.5rem !important',
              border: '1px solid #ccc !important',
              content: '""',
              pointerEvents: 'none', 
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            ':hover::after': {
              borderColor: '#003c50 !important',
            },
            ':focus-within::after': {
              borderColor: '#003c50 !important',
            },
            ':focus::after': {
              borderColor: '#003c50 !important',
            },
            '.ms-Dropdown-caretDownWrapper': {
              borderRadius: '0.5rem',
              backgroundColor: '#ffffff',
              color: '#003c50',
              selectors: {
                ':hover': {
                  color: '#000000',
                },
                ':focus': {
                  outline: 'none',
                  color: '#000000',
                },
              },
            },
          },
    },
};


export const spinButtonProjectMaxDistanceStyle: Partial<ISpinButtonStyles> = {
  root: {
      maxWidth: 300,
      marginTop: 5,
      marginBottom: 15,
      borderRadius: '0.5rem !important',
  },
  spinButtonWrapper: {
      selectors: {
          '::after': {
            borderRadius: '0.5rem !important',
            border: '1px solid #ccc !important',
            content: '""',
            pointerEvents: 'none', 
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          ':hover::after': {
            borderColor: '#003c50 !important',
          },
          ':focus-within::after': {
            borderColor: '#003c50 !important',
          },
          ':focus::after': {
            borderColor: '#003c50 !important',
          },
          '.ms-Dropdown-caretDownWrapper': {
            borderRadius: '0.5rem',
            backgroundColor: '#ffffff',
            color: '#003c50',
            selectors: {
              ':hover': {
                color: '#000000',
              },
              ':focus': {
                outline: 'none',
                color: '#000000',
              },
            },
          },
        },
  },
};


export const messageBarTagsStyle = {
    root: { 
      borderRadius: '0.5rem',
      fontFamily: 'Roboto',
      overflowY: 'auto',
      color: '#003c50',
    },
};

export const spinnerStyle: Partial<ISpinnerStyles> = {
    circle: {
       borderTopColor: '#F07D00',
       borderRightColor: '#f07d004d',
       borderLeftColor: '#f07d004d',
       borderBottomColor: '#f07d004d',
    },
    label: {
        color: '#F07D00'
    }
};


export const geospatialTextfieldStyle = {
  root: { 
    maxWidth: 300,
    marginTop: 5, 
    marginBottom: 15, 
  }, 
  fieldGroup: {
    borderRadius: '0.5rem',
    border: '1px solid #ccc', 
    padding: '2px',
    selectors: {
      ':hover': {
        borderColor: '#003c50', 
      },
      ':focus': {
        borderColor: '#003c50', 
        borderRadius: '0.5rem',
      },
      ':focus-within': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '2px solid #003c50 !important', 
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
  field: { 
    fontFamily: 'Roboto',
    resize: 'vertical' 
  }, 
};