import { API_BASE_URL } from './api/api_base_url';
import React, { useState, useEffect } from 'react';
import { Stack } from '@fluentui/react';
import axios from 'axios';
import DynamicTags from './ui/tags';
import Chat from './ui/chat_window';
import DataTable from './ui/data_table'
import { searchResultDivStyle } from "./react_styles";
import { expanderDivStyle } from "./react_styles";

interface RowData {
  [key: string]: any;
};

interface ChatMessage {
  content: string;
  role: string;
};

interface Props {
  data: RowData[];
  searchQuery: string;
  ragResult: ChatMessage;
  ragMessages: any[];
  boolRag: boolean;
};


const Results: React.FC<Props> = ({ data, searchQuery, ragResult, ragMessages, boolRag }) => {

  // State storing
  //------------------------------------------------------------------------------------------------------------
  const [ragResultText, setRagResultText] = useState<ChatMessage[]>([ragResult]);
  const [messages, setMessages] = useState<ChatMessage[]>([ragResult]);
  const [isSending, setIsSending] = useState(false)


  // Update messages after new search results and when reset is pressed
  //------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (JSON.stringify(ragResult) !== JSON.stringify(ragResultText)) {
      setMessages([ragResult]);
      setRagResultText([ragResult]);
    }
  }, [ragResult]);
  const resetChatHistory = () => {
    setMessages([ragResult]);
  };


  // POST request with user inputs and chat history
  //------------------------------------------------------------------------------------------------------------
  const handleUserMessageSubmit = async (message: string) => {
    setMessages((messages: ChatMessage[]) => [...messages, { content: message, role: 'user' }]);
    setIsSending(true);
    try {
      const { data } = await axios.post(`${API_BASE_URL}/api/chat`,
        {
          ragMessages: ragMessages,
          chatHistory: [...messages, { text: message, role: 'user' }],
        });
      setMessages((messages: ChatMessage[]) => [...messages, { content: data.chatResponse, role: data.chatRole }]);
    } catch (error) {
      console.error('Error during search:', error);
    }
    setIsSending(false);
  };


  // Construct results component as building block for the main app
  //------------------------------------------------------------------------------------------------------------
  return (
    <div style={searchResultDivStyle}>
      <DynamicTags stringToParse={searchQuery} separator=";" label="Query run successfully with the following phrase(s):" />
      <Stack styles={expanderDivStyle}>
        <DataTable data={data} />
      </Stack>
      {(boolRag && ragResult.content &&
        <Stack styles={expanderDivStyle}>
          <Chat messages={messages} onUserMessageSubmit={handleUserMessageSubmit} onResetChat={resetChatHistory} isSending={isSending} />
        </Stack>
      )}
    </div>
  );
};

export default Results;