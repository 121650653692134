import {
  defaultProjectStartDate,
  defaultProjectEndDate,
  defaultProjectAbsenceThreshold,
  defaultProjectPostalCode,
  defaultProjectLocation,
  defaultProjectMaxDistance,
} from "./options_defaults";
import { defaultKwePrompt, defaultRagPrompt } from "../prompts/prompts"
import { SearchSettings } from "../SearchComponent";


//------------------------------------------------------------------------------------------------------------
// USE CASES
//------------------------------------------------------------------------------------------------------------

// USE CASE 1: Hybrid Search with KWE and RAG
//------------------------------------------------------------------------------------------------------------

const avDateMin = new Date(2024, 5, 9);
const avDateMax = new Date(2025, 0, 1);
const entryDateAfter = new Date(1999, 0, 1);
const lastModifiedDateOlder = new Date();
const timeZoneOffsetHours = (-1) * avDateMin.getTimezoneOffset() / 60;

avDateMin.setHours(avDateMin.getHours() + timeZoneOffsetHours);
avDateMax.setHours(avDateMax.getHours() + timeZoneOffsetHours);
entryDateAfter.setHours(entryDateAfter.getHours() + timeZoneOffsetHours);
lastModifiedDateOlder.setHours(lastModifiedDateOlder.getHours() + timeZoneOffsetHours);

export const useCase1: SearchSettings = {
  toggleBasicCvData: true,
  toggleTenders: false,
  toggleNewProjects: false,
  toggleDiamonds: false,
  searchTypeOption: 2,
  toggleRag: true,
  searchModeOption: 0,
  queryTypeOption: 1,
  maxResults: 100,
  avDateMin: avDateMin,
  avDateMax: avDateMax,
  staffingStatus: {
    presumablyAvailable: false,
    availableConfirmed: false,
    offered: false,
    vv: false,
    pvInProgress: false,
    unknown: false,
    noStaffingStatus: false
  },
  staffingPriority: {
    grün: false,
    gelb: false,
    rot: false,
  },
  orgUnit: [
    { key: 'appliedAI', text: 'Applied AI' },
    { key: 'backoffice', text: 'Backoffice' },
    { key: 'buildingsEnergyGridInfrastructure', text: 'Buildings, Energy & Grid Infrastructure' },
    { key: 'businessControlBanking', text: 'Business Control Banking' },
    { key: 'businessControlCorporates', text: 'Business Control Corporates' },
    { key: 'businessControlInsuranceAssetManager', text: 'Business Control Insurance & Asset Manager' },
    { key: 'corporateServices', text: 'Corporate Services' },
    { key: 'creditRisk', text: 'Credit Risk' },
    { key: 'digitalComplianceCorporateFinance', text: 'Digital Compliance & Corporate Finance' },
    { key: 'intinc', text: 'Intinc' },
    { key: 'intincsInitialSkillAdaption', text: 'Intincs & Initial Skill Adaption' },
    { key: 'mainline', text: 'Mainline' },
    { key: 'management', text: 'Management' },
    { key: 'marketNonFinancialRisk', text: 'Market & Non-Financial Risk' },
    { key: 'marketRisk', text: 'Market Risk' },
    { key: 'marketsOperations', text: 'Markets & Operations' },
    { key: 'newMarketOfferings', text: 'New Market Offerings' },
    { key: 'softwareEngineeringBDA', text: 'Software Engineering & BDA' },
    { key: 'technology', text: 'Technology' },
    { key: 'tradingSystems', text: 'Trading Systems' },
    { key: 'valuation', text: 'Valuation' },
  ],
  careerLevel: {
    solutionEngineer: false,
    seniorSolutionEngineer: false,
    consultant: true,
    seniorConsultant: true,
    manager: true,
    seniorManager: false,
    principal: false,
    principalExpert: false,
    partner: false
  },
  careerTrack: {
    yellow: false,
    orange: false,
    orangeFlex: false,
    blue: false,
  },
  projectPostalCode: defaultProjectPostalCode,
  projectLocation: defaultProjectLocation,
  projectMaxDistance: defaultProjectMaxDistance,
  projectStartDate: defaultProjectStartDate,
  projectEndDate: defaultProjectEndDate,
  projectAbsenceThreshold: defaultProjectAbsenceThreshold,
  entryDateAfter: entryDateAfter,
  lastModifiedDateOlder: lastModifiedDateOlder,
  industryItems: [
    { textValue: "Bank^3" },
    { textValue: "Insurance" },
  ],
  expertiseItems: [
    { textValue: "Software*" },
    { textValue: "Programmieren" }
  ],
  skillItems: [
    { textValue: "Python", sliderValue: 2 },
    { textValue: "Jira*", sliderValue: 1 },
    { textValue: "SQL", sliderValue: 2 }
  ],
  searchQuery: `Position: Finanztechnologie-Entwickler (m/w/d)

Wir suchen einen erfahrenen Finanztechnologie-Entwickler (m/w/d), der/die in unserem Unternehmen eine entscheidende Rolle bei der Entwicklung und Implementierung von Softwarelösungen im Finanzwesen einnehmen wird. Als Teil unseres hochqualifizierten Teams werden Sie maßgeblich dazu beitragen, innovative technologische Lösungen zu entwickeln, die unseren Kunden im Finanzsektor einen Wettbewerbsvorteil verschaffen.

Anforderungen:

Abgeschlossenes Studium der Informatik, Wirtschaftsinformatik, Mathematik oder vergleichbare Qualifikation
Fundierte Kenntnisse in der Programmierung, insbesondere in den Sprachen Python, Java oder C++
Erfahrung in der Entwicklung von Finanzsoftware, idealerweise im Bereich Algorithmic Trading, Risikomanagement oder Finanzanalyse
Vertrautheit mit Finanzmarktregulierung und Compliance-Anforderungen (z.B. MiFID II, EMIR, Dodd-Frank)
Starkes analytisches Denkvermögen und die Fähigkeit, komplexe Probleme zu lösen
Teamfähigkeit, Kommunikationsstärke und eine proaktive Arbeitsweise

Aufgaben:

Entwicklung und Implementierung von maßgeschneiderten Softwarelösungen für Kunden im Finanzsektor
Enge Zusammenarbeit mit anderen Entwicklern, Analysten und Finanzexperten, um innovative Lösungen zu konzipieren und umzusetzen
Gewährleistung der Einhaltung von regulatorischen Anforderungen und Best Practices im Finanzbereich
Kontinuierliche Weiterentwicklung bestehender Systeme und Anwendungen, um den sich wandelnden Anforderungen des Marktes gerecht zu werden
Wir bieten eine spannende und herausfordernde Tätigkeit in einem dynamischen Umfeld sowie attraktive Entwicklungsmöglichkeiten. Wenn Sie Teil eines innovativen Teams werden
möchten und über die erforderlichen Fähigkeiten verfügen, senden Sie bitte Ihre Bewerbungsunterlagen an die unten angegebene Adresse.` ,
  kwePrompt: defaultKwePrompt,
  ragPrompt: defaultRagPrompt,
}


// USE CASE 2: Full Text Search using the Query Helper
//------------------------------------------------------------------------------------------------------------

const avDateMin2 = new Date(2024, 5, 9);
const avDateMax2 = new Date(2025, 5, 22);
const entryDateAfter2 = new Date(1999, 0, 1);
const lastModifiedDateOlder2 = new Date();
const timeZoneOffsetHours2 = (-1) * avDateMin2.getTimezoneOffset() / 60;

avDateMin2.setHours(avDateMin2.getHours() + timeZoneOffsetHours2);
avDateMax2.setHours(avDateMax2.getHours() + timeZoneOffsetHours2);
entryDateAfter2.setHours(entryDateAfter2.getHours() + timeZoneOffsetHours2);
lastModifiedDateOlder2.setHours(lastModifiedDateOlder2.getHours() + timeZoneOffsetHours2);

export const useCase2: SearchSettings = {
  toggleBasicCvData: true,
  toggleTenders: false,
  toggleNewProjects: false,
  toggleDiamonds: false,
  searchTypeOption: 0,
  toggleRag: false,
  searchModeOption: 0,
  queryTypeOption: 0,
  maxResults: 100,
  avDateMin: avDateMin2,
  avDateMax: avDateMax2,
  staffingStatus: {
    presumablyAvailable: true,
    availableConfirmed: true,
    offered: false,
    vv: false,
    pvInProgress: false,
    unknown: true,
    noStaffingStatus: true
  },
  staffingPriority: {
    grün: false,
    gelb: false,
    rot: false,
  },
  orgUnit: [
    { key: 'appliedAI', text: 'Applied AI' },
    { key: 'businessControlBanking', text: 'Business Control Banking' },
    { key: 'creditRisk', text: 'Credit Risk' },
    { key: 'mainline', text: 'Mainline' },
    { key: 'technology', text: 'Technology' },
  ],
  careerLevel: {
    solutionEngineer: false,
    seniorSolutionEngineer: false,
    consultant: true,
    seniorConsultant: true,
    manager: false,
    seniorManager: false,
    principal: false,
    principalExpert: false,
    partner: false
  },
  careerTrack: {
    yellow: false,
    orange: false,
    orangeFlex: false,
    blue: false,
  },
  projectPostalCode: defaultProjectPostalCode,
  projectLocation: defaultProjectLocation,
  projectMaxDistance: defaultProjectMaxDistance,
  projectStartDate: defaultProjectStartDate,
  projectEndDate: defaultProjectEndDate,
  projectAbsenceThreshold: defaultProjectAbsenceThreshold,
  entryDateAfter: entryDateAfter2,
  lastModifiedDateOlder: lastModifiedDateOlder2,
  industryItems: [
    { textValue: "Bank" },
    { textValue: "Finanz*" },
  ],
  expertiseItems: [
    { textValue: "Software*" },
    { textValue: "Programmieren" }
  ],
  skillItems: [
    { textValue: "Python", sliderValue: 2 },
    { textValue: "TypeScript", sliderValue: 1 },
    { textValue: "Jira*", sliderValue: 1 }
  ],
  searchQuery: "Web App, Frontend, Backend, Datenbank",
  kwePrompt: defaultKwePrompt,
  ragPrompt: defaultRagPrompt,
}


// USE CASE 3: Tender CV and New Project Mail Search
//------------------------------------------------------------------------------------------------------------

const avDateMin3 = new Date(2024, 0, 1);
const avDateMax3 = new Date(2025, 5, 7);
const entryDateAfter3 = new Date(1999, 0, 1);
const lastModifiedDateOlder3 = new Date();
const timeZoneOffsetHours3 = (-1) * avDateMin2.getTimezoneOffset() / 60;

avDateMin3.setHours(avDateMin3.getHours() + timeZoneOffsetHours3);
avDateMax3.setHours(avDateMax3.getHours() + timeZoneOffsetHours3);
entryDateAfter3.setHours(entryDateAfter3.getHours() + timeZoneOffsetHours3);
lastModifiedDateOlder3.setHours(lastModifiedDateOlder3.getHours() + timeZoneOffsetHours3);

export const useCase3: SearchSettings = {
  toggleBasicCvData: false,
  toggleTenders: true,
  toggleNewProjects: true,
  toggleDiamonds: false,
  searchTypeOption: 0,
  toggleRag: false,
  searchModeOption: 0,
  queryTypeOption: 1,
  maxResults: 100,
  avDateMin: avDateMin3,
  avDateMax: avDateMax3,
  staffingStatus: {
    presumablyAvailable: false,
    availableConfirmed: false,
    offered: false,
    vv: false,
    pvInProgress: false,
    unknown: false,
    noStaffingStatus: false
  },
  staffingPriority: {
    grün: false,
    gelb: false,
    rot: false,
  },
  orgUnit: [
    { key: 'appliedAI', text: 'Applied AI' },
    { key: 'backoffice', text: 'Backoffice' },
    { key: 'buildingsEnergyGridInfrastructure', text: 'Buildings, Energy & Grid Infrastructure' },
    { key: 'businessControlBanking', text: 'Business Control Banking' },
    { key: 'businessControlCorporates', text: 'Business Control Corporates' },
    { key: 'businessControlInsuranceAssetManager', text: 'Business Control Insurance & Asset Manager' },
    { key: 'corporateServices', text: 'Corporate Services' },
    { key: 'creditRisk', text: 'Credit Risk' },
    { key: 'digitalComplianceCorporateFinance', text: 'Digital Compliance & Corporate Finance' },
    { key: 'intinc', text: 'Intinc' },
    { key: 'intincsInitialSkillAdaption', text: 'Intincs & Initial Skill Adaption' },
    { key: 'mainline', text: 'Mainline' },
    { key: 'management', text: 'Management' },
    { key: 'marketNonFinancialRisk', text: 'Market & Non-Financial Risk' },
    { key: 'marketRisk', text: 'Market Risk' },
    { key: 'marketsOperations', text: 'Markets & Operations' },
    { key: 'newMarketOfferings', text: 'New Market Offerings' },
    { key: 'softwareEngineeringBDA', text: 'Software Engineering & BDA' },
    { key: 'technology', text: 'Technology' },
    { key: 'tradingSystems', text: 'Trading Systems' },
    { key: 'valuation', text: 'Valuation' },
  ],
  careerLevel: {
    solutionEngineer: false,
    seniorSolutionEngineer: false,
    consultant: false,
    seniorConsultant: false,
    manager: false,
    seniorManager: false,
    principal: false,
    principalExpert: false,
    partner: false
  },
  careerTrack: {
    yellow: false,
    orange: false,
    orangeFlex: false,
    blue: false,
  },
  projectPostalCode: defaultProjectPostalCode,
  projectLocation: defaultProjectLocation,
  projectMaxDistance: defaultProjectMaxDistance,
  projectStartDate: defaultProjectStartDate,
  projectEndDate: defaultProjectEndDate,
  projectAbsenceThreshold: defaultProjectAbsenceThreshold,
  entryDateAfter: entryDateAfter3,
  lastModifiedDateOlder: lastModifiedDateOlder3,
  industryItems: [],
  expertiseItems: [],
  skillItems: [],
  searchQuery: `quantittive~, "model prototypes"~10, "workshops vendors"~5, /[lp]ast/, bank*, software^5`,
  kwePrompt: defaultKwePrompt,
  ragPrompt: defaultRagPrompt,
}


//------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------