import React, { useState, useEffect } from 'react';
import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { Tag } from "@fluentui/react-components";
import { ITag } from '@fluentui/react/lib/Pickers';
import { IconButton } from '@fluentui/react/lib/Button';
import { dropdownStyles, tagMultiselectDivStyle, tagMultiselectStyle, buttonRemoveAllMultiselectStyle, buttonRemoveMultiselectStyle } from './react_styles_ui';

interface MultiSelectDropdownProps {
  placeholder?: string;
  options: IDropdownOption[];  // General options to respect order in dropdown selection
  optionsStored?: IDropdownOption[];  // Some stored options order to use if order should be adjustable
  selectedOptions: IDropdownOption[];  
  onChange: (selectedOptionsList: IDropdownOption[]) => void;
  adjustOrder: boolean;
}


const MultiSelectDropdown: React.FunctionComponent<MultiSelectDropdownProps> = ({ placeholder, options, optionsStored, selectedOptions = [], onChange, adjustOrder}) => {

  // Initialize state 
  const [optionsUpdate, setOptionsUpdate] = useState<IDropdownOption[]>(optionsStored || options);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(selectedOptions.map((option) => option.key.toString()));
  const [selectedItems, setSelectedItems] = useState<ITag[]>([]);

  // Handle state change events
  const handleChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption<any>, index?: number): void => {
    if (item) {
      if (item.key === "ALL"  && !adjustOrder) {
        const allKeys = options.map((option) => option.key.toString());
        setSelectedKeys(allKeys);
        onChange?.(options);
        const allItems = options.map((option) => ({ key: option.key.toString(), name: option.text }));
        setSelectedItems(allItems);
      } else {
        const updatedKeys = item.selected
          ? [...selectedKeys, item.key.toString()]
          : selectedKeys.filter((key) => key !== item.key.toString());
        const adjustedKeys = adjustOrder ? [...selectedKeys.filter(key => key !== item.key.toString()), item.key.toString()] : updatedKeys;
        const updatedOptions = adjustedKeys
          .map((key) => options.find((option) => option.key.toString() === key))
          .filter((option): option is IDropdownOption<any> => !!option); 
        setSelectedKeys(updatedKeys);
        setOptionsUpdate(updatedOptions);
        onChange?.(updatedOptions);
      }
    }
  };

  // Handle removing tags
  const handleTagRemove = (item: ITag): void => {
    const updatedKeys = selectedKeys.filter((key) => key !== item.key);
    setSelectedKeys(updatedKeys);
    const updatedOptions = adjustOrder
      ? optionsUpdate.filter((option) => updatedKeys.includes(option.key.toString()))
      : options.filter((option) => updatedKeys.includes(option.key.toString()));
    onChange?.(updatedOptions);
    const updatedItems = adjustOrder
      ? optionsUpdate
        .filter((option) => updatedKeys.includes(option.key.toString()))
        .map((option) => ({ key: option.key.toString(), name: option.text }))
      : options
        .filter((option) => updatedKeys.includes(option.key.toString()))
        .map((option) => ({ key: option.key.toString(), name: option.text }));
    setSelectedItems(updatedItems);
  };

  // Handle removing all tags
  const handleRemoveAll = () => {
    setSelectedItems([]);
    setSelectedKeys([]);
    onChange?.([]);
  };

  // Update selected keys
  useEffect(() => {
    setSelectedKeys(selectedOptions.map((option) => option.key.toString()));
  }, [selectedOptions]);

  // Update optionsUpdate state when optionsStored change
  useEffect(() => {
    if (optionsStored) {
      setOptionsUpdate(optionsStored);
    }
  }, [optionsStored]);
  
  // Update selected items
  useEffect(() => {
    const updatedItems = adjustOrder
      ? optionsUpdate
        .filter((option) => selectedKeys.includes(option.key.toString()))
        .map((option) => ({ key: option.key.toString(), name: option.text }))
      : options
        .filter((option) => selectedKeys.includes(option.key.toString()))
        .map((option) => ({ key: option.key.toString(), name: option.text }));
    setSelectedItems(updatedItems);
  }, [optionsUpdate, options, selectedKeys, adjustOrder]);

  // Construct MultiSelectDropdown component
  return (
    <>
      <Dropdown
        placeholder={placeholder}
        selectedKeys={[]}
        onChange={handleChange}
        multiSelect
        options={
          adjustOrder
            ? options.filter(option => !selectedKeys.includes(option.key.toString()))
            : [
                { key: "ALL", text: "All" },
                ...options.filter(option => !selectedKeys.includes(option.key.toString()))
              ]
        }
        styles={dropdownStyles}
        data-testid="dropdown-toggle"
      />
      {selectedItems.length > 0 && (
        <Stack
          horizontal
          verticalAlign="center"
        >  
          <Stack.Item styles={tagMultiselectDivStyle}> 
          {selectedItems.map((item) => (
            <Tag
              key={item.key}
              style={tagMultiselectStyle}
              data-testid="tag"
            >
              {item.name}
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                onClick={() => handleTagRemove(item)}
                styles={buttonRemoveMultiselectStyle}
                data-testid="cancel"
              />
            </Tag>
          ))}
          </Stack.Item>
          <Stack.Item>
            <IconButton 
              onClick={handleRemoveAll} 
              styles={buttonRemoveAllMultiselectStyle}
              iconProps={{ iconName: 'Delete' }}
              data-testid="remove-all-button"
            />
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default MultiSelectDropdown;